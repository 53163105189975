@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

:root {
  --plrdark: #000000;
  --plrlight: #ffffff;
  --plrborder-radius: 4px;
  --plrfont-size: 16px;
  --plrpadding: 1rem;
}

.payl8r-checkout-button {
  color: #ffffff;
  border-radius: var(--plrborder-radius);
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
  font-size: 1rem;
  padding: var(--plrpadding);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &--teal {
    background: -webkit-linear-gradient(340deg, #0fb9c3 10%, #53dccd 60%);
    background: -o-linear-gradient(340deg, #0fb9c3 10%, #53dccd 60%);
    background: linear-gradient(110deg, #0fb9c3 10%, #53dccd 60%);
    border: none;
    color: var(--plrlight);

    &:hover {
      background: -webkit-linear-gradient(340deg, #0fb9c3 10%, #53dccd 60%);
      background: -o-linear-gradient(340deg, #0fb9c3 10%, #53dccd 60%);
      background: linear-gradient(110deg, #0fb9c3 10%, #53dccd 60%);
    }
  }

  &--dark {
    background: var(--plrdark);
    border: none;
    color: var(--plrlight);

    &:hover {
      background: var(--plrdark);
    }
  }
  &--light {
    background: var(--plrlight);
    border: 1px solid #0fb9c3;
    color: var(--dark);

    &:hover {
      background: var(--plrlight);
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &__center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  &__logo {
    background-image: url("https://payl8r.com/frontend/img/payl8r-white-logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 20px;
    position: relative;
    left: 4px;
    top: 2px;

    &--darken {
      background-image: url("https://payl8r.com/frontend/img/payl8r-logo.png");
    }
  }
}

@media only screen and (max-width: 380px) {
  .payl8r-checkout-button {
    padding: calc(var(--plrpadding) / 2);
  }
}
